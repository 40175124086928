import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { loadValhallaPlanets, ValhallaPlanet } from '../../Backend/Network/ValhallaPlanetsAPI';

import { TextPreview } from '../Components/TextPreview';

const Container = styled.div`
  height: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1.2fr);
  column-gap: 16px;
  row-gap: 16px;
`;

const PlanetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

const OwnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 12px;
  color: rgb(112, 122, 131);
`;

const VALHALLA_ADDRESS = '0x9724fDF5aE41570dEcC2D3094C65eafA7E1aB7D4';
const openseaUrl = `https://opensea.io/assets/ethereum/${VALHALLA_ADDRESS}`;

export function ValhallaPlanets() {
  const [valhallaPlanets, setValhallaPlanets] = useState<ValhallaPlanet[]>([]);

  useEffect(() => {
    async function onLoadValhallaPlanets() {
      const valhallaPlanets: ValhallaPlanet[] = await loadValhallaPlanets();

      setValhallaPlanets(valhallaPlanets);
    }

    onLoadValhallaPlanets();
  }, []);

  return (
    <Container>
      {valhallaPlanets.map((planet: ValhallaPlanet) => {
        const splittedTokenURI: string[] = planet.tokenURI.split('/');
        const imageURI: string = splittedTokenURI[splittedTokenURI.length - 1];

        return <a key={planet.id} href={`${openseaUrl}/${planet.id}`} target='_blank' rel="noreferrer">
          <PlanetWrapper>
            <img src={`https://darkforest-valhalla.s3.us-east-2.amazonaws.com/resources/png/${imageURI}.png`} />
            <OwnerWrapper>
              <span>owner:</span>
              <TextPreview text={planet.owner.id} style={{ cursor: 'pointer' }} />
            </OwnerWrapper>
          </PlanetWrapper>
        </a>;
      })}
    </Container>);
}
