import { ApolloClient, ApolloQueryResult, InMemoryCache, NormalizedCacheObject, gql } from '@apollo/client'

const VALHALLA_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/dudendy/valhalla';

const planetsQuery = `
  {
    planets(
      first: 1000
      orderBy: createdAtTimestamp
      orderDirection: desc
    ) {
      id
      tokenURI
      owner {
        id
      }
    }
  }
`;

export interface ValhallaPlanet {
  id: string;
  tokenURI: string;
  owner: {
    id: string;
  }
}

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: VALHALLA_SUBGRAPH_URL,
  cache: new InMemoryCache()
});

export async function loadValhallaPlanets(): Promise<ValhallaPlanet[]> {
  return client
    .query({
      query: gql(planetsQuery),
    }).then((res: ApolloQueryResult<{ planets: ValhallaPlanet[] }>) => res.data.planets);
};
